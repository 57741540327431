// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sitemap-jsx": () => import("./../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-templates-page-js": () => import("./../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-productcategory-js": () => import("./../../src/templates/productcategory.js" /* webpackChunkName: "component---src-templates-productcategory-js" */),
  "component---src-templates-project-js": () => import("./../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

